import { makeAutoObservable } from 'mobx'
import { Message } from 'apolloHooks/subscription/useListenForWhisperMessages'

export interface GeneralNotification {
  type: 'JOINED' | 'LEFT',
  timestamp: Date,
}

class Chat {
  generalMessages: (Message | GeneralNotification)[]

  constructor() {
    makeAutoObservable(this)
    this.generalMessages = []
  }

  addGeneralMessage(message: Message | GeneralNotification): void {
    this.generalMessages.push(message)
  }
}

export default Chat
