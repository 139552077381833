import { makeAutoObservable } from 'mobx'

class Room {
  roomModeratorIds: string[]

  constructor() {
    makeAutoObservable(this)

    this.roomModeratorIds = []
  }

  setRoomModeratorIds(ids: string[]): void {
    this.roomModeratorIds = ids
  }
}

export default Room
