import { makeAutoObservable } from 'mobx'

class RequestMeeting {
  currentStep = 0

  lastStep: number | undefined

  requestMeetingVisible = false

  status = ''

  isViewMode = false

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentStep(step: number): void {
    this.currentStep = step
  }

  setLastStep(step: number): void {
    this.lastStep = step
  }

  setRequestMeetingVisible(visible: boolean): void {
    this.requestMeetingVisible = visible
  }

  setStatus(status: string): void {
    this.status = status
  }

  setViewMode(mode: boolean): void {
    this.isViewMode = mode
  }
}

export default RequestMeeting
