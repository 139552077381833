import { makeAutoObservable } from 'mobx'
import { Appointment } from 'apolloHooks/query/useAppointments'

class Appointemnt {
  selectedRequest: Appointment | null | undefined

  constructor() {
    makeAutoObservable(this)
    this.selectedRequest = null
  }

  setSelectedRequest(request: Appointment | null | undefined): void {
    this.selectedRequest = request
  }
}

export default Appointemnt
