import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import useBreakPoint from 'hooks/useBreakPoint'

interface Props {
  children: JSX.Element,
}

const useStyles = makeStyles({
  containerAnchorOriginTopRight: {
    top: '62px',
  },
})

const CustomSnackbarProvider = ({ children }: Props): JSX.Element => {
  const isMobileView = useBreakPoint('md')
  // eslint-disable-next-line
  const [audioRing] = useState(new Audio(require('assets/audios/messageNotification.mp3').default))
  const classes = useStyles()

  return (
    <SnackbarProvider
      maxSnack={isMobileView ? 1 : 3}
      TransitionProps={
        // @ts-expect-error: incorrect library type
        { direction: 'up' }
      }
      autoHideDuration={isMobileView ? 5000 : 10000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onEnter={() => audioRing.play()}
      classes={{ containerAnchorOriginTopRight: classes.containerAnchorOriginTopRight }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default CustomSnackbarProvider
