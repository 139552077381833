import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import cookie from 'js-cookie'

const exhibitionLink = createHttpLink({ uri: process.env.REACT_APP_EXHIBITION_QUERY_URL })
const exhibitionAdminLink = createHttpLink({ uri: process.env.REACT_APP_EXHIBITION_ADMIN_URL })
const generalLink = createHttpLink({ uri: process.env.REACT_APP_API_URL })

const exhibitionLinks = split(
  operataion => {
    const context = operataion.getContext()

    return context.isAdmin
  },
  exhibitionAdminLink,
  exhibitionLink,
)

const httpLink = split(
  () => process.env.REACT_APP_BUILD_TYPE === 'exhibition',
  exhibitionLinks,
  generalLink,
)

const getToken = () => {
  const token = cookie.get('accessToken')

  return token ? `Bearer ${token}` : ''
}

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: getToken(),
  },
}))

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_WS_URL as string,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      Authorization: getToken(),
    }),
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink),
)

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        findUserProfiles: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            const merged = existing ? existing.slice(0) : []
            // eslint-disable-next-line
            for (let i = 0; i < incoming.length; ++i) {
              merged[args?.page - 1 + i] = incoming[i]
            }

            return merged
          },
        },
      },
    },
    Exhibitor: {
      fields: {
        files: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
  },
})

export default new ApolloClient({
  link: splitLink,
  cache,
})
