import { makeAutoObservable } from 'mobx'
import { Team, Exhibitor as ExhibitorObject } from 'apolloHooks/query/useExhibitorDetails'
import { Room } from 'apolloHooks/query/useFloorPlan'
import { LocalStream } from 'libs/ion/stream'
import Client from 'libs/ion/client'
import { ViewMode } from 'components/RoomSwitch'

interface ChatListenParams {
  videoChatId: string,
}

interface LiveStreamParams {
  chatId: string,
  roomName: string,
  sessionId: string,
}

class Exhibitor {
  selectedExhibitorId: string | undefined

  selectedExhibitorName: string | undefined

  selectedFloorPlanId = 'test'

  generalChatVisible = false

  destinationUser: Team | undefined | null

  chatId = 'general'

  videoChatVisible = false

  remoteStreams: { [key: string]: MediaStream | undefined } | undefined

  localStream: LocalStream | null

  previewLocalStream: LocalStream | null

  localClient: Client | null

  chatListenParams: ChatListenParams | null

  callRequestVisible = false

  sharingStream: LocalStream | null

  unreadMessagesCount = Object.values(
    JSON.parse(window.localStorage.getItem('unreadMessages') as string) || {},
  ).reduce((acc, cur: any) => acc + cur.length, 0) as number

  videoDestinationUser: Team | undefined | null

  unreadVideoMessagesCount = 0

  liveStreamChatVisible = false

  roomName: string | undefined

  viewMode: ViewMode

  sessionId: string | undefined

  unreadQaMessagesCount = 0

  exhibitorDetails: ExhibitorObject | null | undefined

  generalChatJoined = false

  unreadGeneralChatMessagesCount = 0

  whisperChatVisible = false

  requestMeetingDisabled = false

  listenUsers: string[]

  selectedRoom: Room | null

  constructor() {
    makeAutoObservable(this)
    this.localStream = null
    this.previewLocalStream = null
    this.localClient = null
    this.chatListenParams = null
    this.sharingStream = null
    this.viewMode = '2d'
    this.listenUsers = JSON.parse(window.localStorage.getItem('listenUsers') as string) || []
    this.selectedRoom = null
  }

  setSelectedExhibitorId(id: string | undefined): void {
    this.closeGeneralChat()
    this.selectedExhibitorId = id
    this.selectedRoom = null
  }

  setSelectedExhibitorName(name: string): void {
    this.selectedExhibitorName = name
  }

  clearSelectedExhibitor(): void {
    this.selectedExhibitorId = undefined
    this.selectedExhibitorName = undefined
  }

  setSelectedFloorPlanId(id: string): void {
    this.selectedFloorPlanId = id
  }

  openGeneralChat(): void {
    this.generalChatVisible = true
    this.generalChatJoined = true
    this.unreadGeneralChatMessagesCount = 0
  }

  closeGeneralChat(): void {
    this.generalChatVisible = false
  }

  leaveFromGeneralChat(): void {
    this.closeGeneralChat()
    this.generalChatJoined = false
  }

  increaseUnreadMessagesCount(): void {
    this.unreadMessagesCount += 1
  }

  decraseUnreadMessagesCount(count: number): void {
    const newCount = this.unreadMessagesCount - count

    this.unreadMessagesCount = newCount >= 0 ? newCount : 0
  }

  setDestinationUser(user: Team | null | undefined): void {
    this.destinationUser = user
  }

  setVideoDestinationUser(user: Team | null | undefined): void {
    this.videoDestinationUser = user
  }

  openVideoChat(chatId: string): void {
    this.chatId = chatId
    this.videoChatVisible = true
  }

  closeVideoChat(): void {
    this.videoChatVisible = false
    this.chatId = 'general'
    this.videoDestinationUser = null
    this.localClient?.close()
    this.chatListenParams = null
    this.remoteStreams = undefined
    this.localStream?.getTracks().forEach(track => track.stop())
    this.localStream = null
    this.callRequestVisible = false
    this.sharingStream?.getTracks().forEach(track => track.stop())
    this.sharingStream = null
    this.previewLocalStream = null
  }

  addRemoteStream(stream: { [key: string]: MediaStream | undefined }): void {
    this.remoteStreams = {
      ...this.remoteStreams,
      ...stream,
    }
  }

  setLocalStream(stream: LocalStream | null): void {
    this.localStream = stream
    this.previewLocalStream = null
  }

  setPreviewLocalStream(stream: LocalStream | null): void {
    this.previewLocalStream = stream
  }

  setLocalClient(client: Client): void {
    this.localClient = client
  }

  setChatListenParams(params: ChatListenParams | null): void {
    this.chatListenParams = params
  }

  openCallRequest(): void {
    this.callRequestVisible = true
  }

  closeCallRequest(): void {
    this.callRequestVisible = false
  }

  incrementUnreadVideoMessagesCount(): void {
    this.unreadVideoMessagesCount += 1
  }

  resetUnreadVideoMessagesCount(): void {
    this.unreadVideoMessagesCount = 0
  }

  setSharingStream(stream: LocalStream | null): void {
    this.sharingStream = stream
  }

  openLiveStream({ chatId, roomName, sessionId }: LiveStreamParams): void {
    this.chatId = chatId
    this.liveStreamChatVisible = true
    this.roomName = roomName
    this.sessionId = sessionId
  }

  closeLiveStream(): void {
    this.generalChatVisible = false
    this.chatId = this.videoChatVisible ? this.chatId : 'general'
    this.liveStreamChatVisible = false
    this.roomName = undefined
    this.sessionId = undefined
  }

  setViewMode(viewMode: ViewMode): void {
    this.viewMode = viewMode
  }

  incrementUnreadQaMessagesCount(): void {
    this.unreadQaMessagesCount += 1
  }

  resetUnreadQaMessagesCount(): void {
    this.unreadQaMessagesCount = 0
  }

  setExhibitorDetails(details: ExhibitorObject | null | undefined): void {
    this.exhibitorDetails = details
  }

  incrementGeneralChatMessagesCount(): void {
    this.unreadGeneralChatMessagesCount += 1
  }

  resetUnreadGeneralMessagesCount(): void {
    this.unreadGeneralChatMessagesCount = 0
  }

  setWhisperChatVisible(visible: boolean): void {
    this.whisperChatVisible = visible
  }

  setRequestMeetingDisabled(disabled: boolean): void {
    this.requestMeetingDisabled = disabled
  }

  setListenUsers(listenUser: string): void {
    const index = this.listenUsers.indexOf(listenUser)

    if (index >= 0) {
      const newLinstenUsers = [...this.listenUsers]

      newLinstenUsers.splice(index, 1)
      this.listenUsers = newLinstenUsers
    } else {
      this.listenUsers.push(listenUser)
    }
  }

  setSelectedRoom(room: Room | null): void {
    this.closeGeneralChat()
    this.selectedRoom = room
    this.selectedExhibitorId = undefined
  }
}

export default Exhibitor
