import { lazy, Suspense } from 'react'
import ScrollToTop from './components/ScrollToTop'

const AttendeeRoutes = lazy(() => import('./components/AttendeeRoutes'))
const ExhibitorRoutes = lazy(() => import('./components/ExhibitorRoutes'))
const ExhibitionRoutes = lazy(() => import('./components/ExhibitionRoutes'))

const Routes = (): JSX.Element => (
  <>
    <ScrollToTop />
    <Suspense fallback={null}>
      {{
        attendee: <AttendeeRoutes />,
        exhibitor: <ExhibitorRoutes />,
        exhibition: <ExhibitionRoutes />,
      }[process.env.REACT_APP_BUILD_TYPE as string]}
    </Suspense>
  </>
)

export default Routes
