import { makeAutoObservable } from 'mobx'
import { SelectedRoomItem } from 'components/ScheduleTitle'

class Schedule {
  selectedRoomItem: SelectedRoomItem | null | undefined

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedRoomItem(room: SelectedRoomItem | null): void {
    this.selectedRoomItem = room
  }
}

export default Schedule
