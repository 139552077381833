import { makeAutoObservable } from 'mobx'
import { UserProfile } from 'apolloHooks/query/useMe'

class AttendeeList {
  selectedAttendee: UserProfile | undefined | null

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedAttendee(profile: UserProfile | null): void {
    this.selectedAttendee = profile
  }
}

export default AttendeeList
