import React, { Suspense } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { ApolloProvider } from '@apollo/client'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { BrowserRouter as Router } from 'react-router-dom'
import { store, StoreContext } from 'hooks/useStore'
import { AuthProvider } from 'hooks/useAuth'
import Routes from './components/Routes'
import SnackbarProvider from './components/SnackbarProvider'
import apolloClient from './apolloClient'
import theme from './theme'
import './i18n'
import './styles.css'

const App = (): JSX.Element => (
  <Suspense fallback="loading">
    <StoreContext.Provider value={store}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Router>
              <AuthProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Routes />
                </MuiPickersUtilsProvider>
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloProvider>
    </StoreContext.Provider>
  </Suspense>
)

export default App
