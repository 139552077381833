import { createMuiTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: true;
    lg: true;
    xl: false;
  }
}

const defaultTheme = createMuiTheme({
  breakpoints: {
    values: {
      md: 0,
      lg: 1024,
    },
  },
})

const computedBody = getComputedStyle(document.body)
const primaryMain = computedBody.getPropertyValue('--primary-color').trim()
const secondaryMain = computedBody.getPropertyValue('--text-foreground-color').trim()
const contrastText = computedBody.getPropertyValue('--appbar-text-color').trim()
const backgroundColor = computedBody.getPropertyValue('--background-color').trim()

export default createMuiTheme({
  ...defaultTheme,
  typography: {
    fontFamily: 'Roboto',
  },
  palette: {
    primary: { main: primaryMain, contrastText },
    secondary: { main: secondaryMain },
  },
  overrides: {
    MuiInputLabel: {
      formControl: {
        left: 8,
      },
      filled: {
        left: 0,
      },
    },
    MuiInputBase: {
      root: {
        paddingLeft: 8,
      },
    },
    MuiFilledInput: {
      root: {
        paddingLeft: 0,
      },
    },
    MuiTab: {
      root: {
        padding: '6px 16px',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: 'calc(100% - 128px)',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '8px',
      },
      sizeLarge: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '100%',
      },
    },
    MuiDialogActions: {
      root: {
        padding: 16,
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor,
      },
    },
    MuiListItem: {
      root: {
        fontSize: '16px',
      },
    },
  },
})
