import { makeAutoObservable } from 'mobx'
import { DownloadSignal } from 'apolloHooks/subscription/useListenForDownloadSignalling'

class FileMessage {
  peers: { [key: string]: any }

  queue: { [key: string]: DownloadSignal[] }

  downloadingFilesCount: number

  ongoingDownloads: { [key: string]: DownloadSignal[] }

  activeDownloadingCount: { [key: string]: number }

  downloadSignal: DownloadSignal | undefined

  constructor() {
    makeAutoObservable(this)

    this.peers = {}
    this.queue = {}
    this.downloadingFilesCount = 0
    this.ongoingDownloads = {}
    this.activeDownloadingCount = {}
  }

  addPeer(transactionId: string, peer: any): void {
    this.peers = { ...this.peers, [transactionId]: peer }
  }

  removePeer(transactionId: string): void {
    this.peers = { ...this.peers, [transactionId]: undefined }
  }

  addQueueItem(downloadSignal: DownloadSignal): void {
    if (this.queue[downloadSignal.fileId]) {
      this.queue[downloadSignal.fileId].push(downloadSignal)
    } else {
      this.queue[downloadSignal.fileId] = [downloadSignal]
    }
  }

  shiftQueue(fileId: string): void {
    this.queue[fileId].shift()
  }

  removeFromQueue(fileId: string, transactionId: string): void {
    this.queue[fileId] = this.queue[fileId].filter(i => i.transactionId !== transactionId)
  }

  incrementDownloadingFilesCount(): void {
    this.downloadingFilesCount += 1
  }

  decrementDownloadingFilesCount(): void {
    this.downloadingFilesCount -= 1
  }

  incrementOngoingDownloads(fileId: string, downloadSignal: DownloadSignal): void {
    if (this.ongoingDownloads[fileId]) {
      this.ongoingDownloads[fileId].push(downloadSignal)
    } else {
      this.ongoingDownloads[fileId] = [downloadSignal]
    }
  }

  decrementOngoingDownloads(fileId: string, transactionId: string): void {
    this.ongoingDownloads[fileId] = this.ongoingDownloads[fileId].filter(i => i.transactionId !== transactionId)
  }

  incrementActiveDownloadingCount(transactionId: string): void {
    if (this.activeDownloadingCount[transactionId]) {
      this.activeDownloadingCount[transactionId] += 1
    } else {
      this.activeDownloadingCount[transactionId] = 1
    }
  }

  decrementActiveDownloadingCount(transactionId: string): void {
    this.activeDownloadingCount[transactionId] -= 1
  }

  resetActiveDownloadingCount(transactionId: string): void {
    this.activeDownloadingCount[transactionId] = 0
  }

  resetDownloadingInfo(fileId: string): void {
    this.queue[fileId]?.forEach(i => this.resetActiveDownloadingCount(i.transactionId))
    this.queue[fileId] = []
    this.ongoingDownloads[fileId] = []
  }

  setDownloadSignal(downloadSignal: DownloadSignal): void {
    this.downloadSignal = downloadSignal
  }
}

export default FileMessage
