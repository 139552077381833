import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const useBreakPoint = (breakPoint: 'md' | 'lg', condition: 'up' | 'down' | 'only' = 'only'): boolean => {
  const theme = useTheme()
  const isTargetBreakPoint = useMediaQuery(theme.breakpoints[condition](breakPoint))

  return isTargetBreakPoint
}

export default useBreakPoint
