import { createContext, useContext } from 'react'
import { enableLogging } from 'mobx-logger'
import Exhibitor from 'containers/general/Home/store'
import RequestMeeting from 'components/RequestMeetingModal/store'
import AttendeeList from 'containers/general/AttendeeList/store'
import Schedule from 'containers/general/Schedule/store'
import Room from 'containers/attendee/Room/store'
import Appointment from 'containers/exhibitor/Appointment/store'
import Chat from 'components/Chat/store'
import FileMessage from 'components/FileMessage/store'

export interface Store {
  exhibitorStore: Exhibitor,
  meetingStore: RequestMeeting,
  attendeeStore: AttendeeList,
  scheduleStore: Schedule,
  roomStore: Room,
  appointmentStore: Appointment,
  chatStore: Chat,
  fileMessageStore: FileMessage,
}

export const store: Store = {
  exhibitorStore: new Exhibitor(),
  meetingStore: new RequestMeeting(),
  attendeeStore: new AttendeeList(),
  scheduleStore: new Schedule(),
  roomStore: new Room(),
  appointmentStore: new Appointment(),
  chatStore: new Chat(),
  fileMessageStore: new FileMessage(),
}

if (process.env.NODE_ENV === 'development') {
  enableLogging({ reaction: false, action: true })
}

export const StoreContext = createContext(store)
export const useStore = () => useContext(StoreContext)
